export const environment = {
  production: true,
  hmr: false,
  debug: false,
  client: 'Kia America -',
  hostName: 'analytics.kiadigitalleads.com',
  environmentName: 'production',
  appName: 'Kia Digital Analytics',
  ssoPassword: 'ShiftDigital#123',
  applicationInsights: {
    instrumentationKey: '89e9fc80-f860-464a-9902-aab5a8f81a22'
  },
  defaultLocale: 'en',
  availableLocales: ['en'],
  timeZone: 'Pacific Standard Time',
  showLocaleSelector: 'false',
  numberOfMonthsToShowDefault: 12,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1],
  closeRateLookbackMonths: 3,
  defaultChartColors: ['#384752', '#52616C', '#05141F', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://kia-analytics-api-prod.azurewebsites.net/api',
  authUri: 'https://kia-analytics-api-prod.azurewebsites.net/token',
  baseV5ApiUri: 'https://kiaus-analytics-apiv5-prod.azurewebsites.net/api',
  retailerToolsBaseUri: 'https://kiaus-retailertools-web-prod.azurewebsites.net',
  retailerToolsIncludeAuth: true,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/kia-us/favicon.ico',
  loginRedirectUrl: '',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: false,
  v5Reports: []
};
